import React, {useCallback, useMemo} from 'react';
import {
  Page,
  Card,
  Stack,
  Layout,
  TextStyle,
  Subheading,
  Heading,
  Button,
} from '@shopify/polaris';
import {navigate} from 'gatsby';
import CustomizationTab from './customizationTab';
import CreateOfferSteps from '../../createOfferSteps';
import PlusLogo from '../../../../../images/PlusLogo.png';
import './checkoutExtensionGuide.css';
import styled from 'styled-components';
import Onboarding from '../../../../../components/onBoarding';

const PageWrapper = styled.div`
  .Polaris-Page {
    max-width: 120rem;
  }
`

const CheckoutExtensionGuide = (props) => {
  const {location} = props;
  const rules = location && location.state && location.state.rules;
  const goToCustomization = useCallback(() => {
    navigate("/app/customizations", {
      state: location.state,
      replace: true,
    })
  }, [rules]);

  
  return (
    <PageWrapper>
    <Page
      breadcrumbs={[{
        onAction: goToCustomization
      }]}
      title={`Create new`}
    >
      <Layout>
        <Layout.Section>
          <Card>
            <Card.Section>
              <CreateOfferSteps step={1}/>
              <Stack distribution='center'>
                <div className='Guide-wrapper'>
                  <div>
                  <Stack distribution='center'>
                    <Subheading>
                      <TextStyle variation='subdued'>
                        Create a customization
                      </TextStyle>
                    </Subheading>
                  </Stack>
                  <Onboarding rules={rules} customization/>
                  </div>
                </div>
              </Stack>
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
    </PageWrapper>
  );
};

export default CheckoutExtensionGuide;